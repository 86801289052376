<template>
    <div>
        <v-card class="mx-auto">
            <v-card-title style="display: flex; justify-content: space-between; align-items: center;">

                <span>Tag History</span>


                <div style="display: flex; align-items: center; gap: 8px;">
                    <v-btn color="success" variant="outlined" @click="showAllTagHistory"
                        style="display: flex; align-items: center;">
                        View all
                        <v-icon size="20" color="primary" style="margin-left: 4px;">mdi-history</v-icon>
                    </v-btn>
                    <v-icon size="20" color="red" @click="closeHistory" style="cursor: pointer;">mdi-close</v-icon>
                </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-virtual-scroll :items="taghistory" item-height="60">
                <template v-slot:default="{ item }">
                    <div :style="item.status === 'UnTagged'
                        ? 'display: flex; justify-content: flex-end; margin: 5px 8px;'
                        : 'display: flex; justify-content: flex-start; margin: 5px 8px;'">
                        <v-sheet :style="`max-width: 70%; padding: 3px; border-radius: 12px; word-wrap: break-word; 
                  background-color: ${item.status === 'UnTagged' ? '#DDDDDD' : '#CFEDDA'}; color: black;`">
                            <v-list-item>
                                <template v-slot:title>
                                    {{(!item.is_auto_tag || item.status==='UnTagged')?getUserName(item.user_id):''}} {{  item.status }}
                                     <!-- <div v-if="item.user_id">{{ getUserName(item.user_id) }} {{ item.status }}</div>
                                     <div v-else>{{ item.status }}</div> use this/above is complex and for testing-->
                                    <v-icon size="15" v-if="item.is_mass_tag" color="red">mdi-tag-multiple-outline</v-icon>
                                    <v-icon size="15" v-else-if="item.is_auto_tag" color="red">mdi-auto-mode</v-icon>
                                    <v-icon size="15" v-else color="red">mdi-tag-outline</v-icon>
                                </template>
                                <template v-slot:prepend>
                                    <UserPopup v-if="!item.is_auto_tag" :user_id=item.user_id :tooltip="false" />
                                    <v-avatar v-else size="2rem" color="blue">
                                        <v-icon color="black">mdi-progress-tag</v-icon>
                                    </v-avatar>

                                </template>
                                <template v-slot:subtitle>
                                    <div v-if="item?.message">
                                        <ol>
                                            <li v-for="(line, index) in item.message.split('\n')" :key="index" style="text-align: left;">
                                                 {{ line }}
                                            </li>
                                        </ol>
                                    </div>
                                    {{ displayDateTime(item.time) }}
                                </template>
                                <template v-slot:append>
                                    <v-btn size="25" icon :color="'#D1E9F6'">
                                        <v-tooltip activator="parent" location="bottom">{{
                                            tagsHashnew[item.tag_id].tag_name }}</v-tooltip>
                                        <v-icon size="20" :color="tagsHashnew[item.tag_id].tag_color">
                                            mdi-{{ tagsHashnew[item.tag_id].tag_icon }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </v-list-item>
                        </v-sheet>
                    </div>
                </template>
            </v-virtual-scroll>
        </v-card>
    </div>
</template>


<script>
import TagService from "@/services/tagService";
import { mapState, mapGetters } from "vuex";
import UserPopup from "../Avatar/UserPopup.vue";
import {displayDateTime} from "@/util/util"
export default {
    name: "TagHistory",
    data() {
        return {
            taghistory: [],
        }
    },
    props: {
        tagId: { type: Number },
    },
    watch: {
        tagId(newval) {
            this.getHistory(newval)
        }
    },
    computed: { UserPopup },
    computed: {
        ...mapState(['contactState']),
        ...mapGetters("people", ["getUserName"]),
        ...mapGetters("tags", ["tagsHashnew"]),
    },
    methods: {
        displayDateTime,
        async showAllTagHistory() {
            try {
                let response = await TagService.getAllContactTagHistory(this.contactState.contact_id);
                console.log(response.data);

                this.taghistory = response.data;
            } catch (error) {
                console.error(error)
            }
        },
        async getHistory(tagId) {
            try {
                let response = await TagService.getContactTagHistory(this.contactState.contact_id, tagId);
                console.log(response.data);

                this.taghistory = response.data;
            } catch (error) {
                console.error(error)
            }
        },
        closeHistory() {
            this.$emit('close');
        }
    },
    async mounted() {
        await this.getHistory(this.tagId);
    }
}
</script>

<style></style>