<template>
    <v-dialog :dialog="dialog" max-width="600px">
      <v-card>
        <v-card-title  class="d-flex justify-space-between align-center">{{ isEdit ? "Edit" : "Add New" }}{{ isType ? " Contact Type" : " Tag"}}
          <v-icon v-if="!isType" icon size="50" :color="data.color"
            >mdi-{{ data.description }}</v-icon>
            <v-icon size="20" color="red"  @click="closeDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="data.name" :label="(isType) ? 'Type Name' : 'Tag Name'" variant="outlined"
              :disabled="(isType)?false:can_EditTag"
              :rules="[(v) => !!v || 'Name is required',
                 (v) => isEdit ? !isDuplicateNameForEdit(v) || 'This name is already in use' : !isDuplicateName(v) || 'This name is already in use']"
  
            ></v-text-field>
  
            <v-text-field v-if="isType" v-model="data.description" variant="outlined" label="Type Description"></v-text-field>
            <div v-else>
              <div v-for="(name, index) in tagIcons" :key="index" style="display: inline;">
                <!-- description -> tag icon name -->
                <v-btn :disabled="!isDuplicateicon(name) || can_EditTag" icon style="margin-left: 1%; display: inline" 
                  @click="data.description = name"
                  >
                  <v-icon icon small :color="data.color">mdi-{{ name }}</v-icon>
                </v-btn>
              </div>
            </div>
  
            <v-container fluid>
              <v-row justify="center">
                <v-col cols="12" md="6" class="d-flex justify-center">
                  <v-color-picker  v-model="data.color" :swatches="swatches" class="ma-2" show-swatches hide-mode-switch hide-inputs
                    dot-size="15" swatches-max-height="75" width="400" canvas-height="80"></v-color-picker>
                </v-col>
              </v-row>
            </v-container>
  
            <v-container fluid>
              <v-row>
                <v-col class="d-flex align-center justify-start">
                  <v-switch v-model="data.switch1" :label="(isType) ? 'Can Be Set' : 'Enable Auto'" color="success"></v-switch>
                </v-col>
  
                <v-col class="d-flex align-center justify-start">
                  <v-switch v-model="data.switch2" :label="(isType) ? 'Can Be Change' : 'Block Further Usage'" :color="(isType) ? 'success':'red'"></v-switch>
                </v-col>
  
                <!-- <v-col v-if="!isType" class="d-flex align-center justify-start">
                  <v-switch v-model="data.switch3" label="Enable for All" color="success"></v-switch>
                </v-col> -->
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-icon v-if="isEdit && !isType && !can_EditTag" size="30" color="red" @click="delete_Tag" elevation="2" style="margin-left: 5%;">mdi-delete</v-icon>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="handleClick" :disabled="!valid">{{
            isEdit ? "Update" : "Create" }}</v-btn>
          <v-btn color="red darken-1" text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  
  <script>
  import MiscService from '@/services/miscService';
  import TagService from '@/services/tagService';
  import { mapActions,mapGetters } from "vuex";
  import EventBus from "@/eventBus";
  export default {
    data() {
      return {
        data:{
        id : null,
        name: "",
        description: "",// tag icon & type desc
        color: "",
        switch1: false,
        switch2: false,
        },
        can_EditTag : false,
        originalName : "", // for name validation
        isType: Boolean, // is type or tag
        swatches: [
          ['#FF0000', '#F95454', '#EC8305'],
          ['#F3C623', '#A900FF', '#FB00FF'],
          ['#FF5E00', '#06B994', '#3B1E54'],
          ['#00FF9C', '#08C2FF', '#BF2EF0'],
          ['#0000FF', '#0000AA', '#B17457'],
        ],
        valid: true,
        tagIcons: [
          "tag",
          "creation-outline",
          "face-agent",
          "account-check",
          "tooth",
          "exclamation-thick",
          "hospital-building",
          "close",
        ],
      };
    },
    props: {
      dialog: Boolean,
      contactType: Object,
      incoming_Tag: Object,
      isEdit: Boolean,
    },
    computed:{
      ...mapGetters("tags",["tags"]),
      ...mapGetters("people",["contactTypes"])
    },
    methods: {
      isDuplicateName(name) {
        if(this.isType){
          return  this.contactTypes.find((type)=> type.type_name.toLowerCase() === name.toLowerCase().trim());
        }else{
          return  this.tags.find((tag)=> tag.tag_name.toLowerCase() === name.toLowerCase().trim());
        }
      },
      isDuplicateNameForEdit(name) {
        if(this.isType){
          return name?.toLowerCase().trim() !== this.originalName?.toLowerCase().trim() && 
          this.contactTypes.find((type)=> type.type_name.toLowerCase() === name.toLowerCase().trim());
        }else{
          return name?.toLowerCase().trim() !== this.originalName?.toLowerCase().trim() && 
          this.tags.find((tag)=> tag.tag_name.toLowerCase() === name.toLowerCase().trim());
        }
      },
      isDuplicateicon(name) {
        if(this.tags.length)
            return  !this.tags.find((tag)=> tag.tag_icon.toLowerCase() === name.toLowerCase());
        else
          return true;
      },
      handleClick() {
      if (this.isType) {
        this.create_update_type();
      } else {
        this.create_update_tag();
      }
    },
      async create_update_type() {
        let contact_Type ={
          type_id: this.data.id,  
          type_name: this.data.name,
          type_desc: this.data.description,
          color: this.data.color,
          can_be_set: this.data.switch1,
          can_be_changed: this.data.switch2,
        }
        if (!this.isEdit) {
          // Create new contact type
          const response = await MiscService.createContactType(contact_Type);
          console.log("Response from createContactType:", response);
          if (response.status == 201) {
            this.$emit('save', response.data);//To ContactTypes component 
          }
        }else{//edit contact type
              this.$emit('save', contact_Type);
          }
        this.closeDialog();
      },
      closeDialog() {
        this.$emit('close');
      },
      ...mapActions('tags', ["createTag", "updateTag", "deleteTag"]),
  
      async delete_Tag() {
        
        if (this.isEdit) {
          const userConfirm = confirm("Do you want to Delete this Tag ?");
          if (userConfirm) {
            try {
              await this.deleteTag(this.data.id);
              this.closeDialog();
            } catch (error) {
              console.error(error);
            }
          }
  
        }
      },
      async create_update_tag() {
        if(!this.data.description){
          EventBus.$emit("alert", {
            message: "Icon Not Selected",
            type: "warning-snack",
          });
          return;
        }
        let tag = {
            tag_name: this.data.name,
            tag_icon: this.data.description,
            tag_color: this.data.color,
            enable_auto : this.data.switch1,
            unlist :this.data.switch2
          };
        if (!this.isEdit) {
          // Creating new Tag
          try {
            await this.createTag(tag);
            this.$emit("close");
          } catch (error) {
            console.error(error);
          }
        } else {
          //Edit Tag
          try {
            await this.updateTag({tag_data:tag,tag_id:this.data.id});
            this.$emit("close");
          } catch (error) {
            console.error(error);
          }
        }
      },
    },
    async mounted() {
      this.isType = (this.contactType!=null)?true:false;
  
      if(this.isType){
        this.data.id = this.contactType.type_id;
        this.data.name = this.contactType.type_name;
        this.originalName =  this.contactType.type_name;
        this.data.description = this.contactType.type_desc;
        this.data.color = this.contactType.color;
        this.data.switch1 = this.contactType.can_be_set;
        this.data.switch2 = this.contactType.can_be_changed;
      }else if(!this.isType){
        this.data.id = this.incoming_Tag.id;
        this.data.name = this.incoming_Tag.name;
        this.originalName =  this.incoming_Tag.name;
        this.data.description = this.incoming_Tag.icon;
        this.data.color = this.incoming_Tag.color;
        this.data.switch1 = this.incoming_Tag.enable_auto;
        this.data.switch2 = this.incoming_Tag.unlist;
        
      }
      if(!this.isType && this.isEdit){
        try {
          // if tag is associated with any contact it can not allowed to edit name and icon.
          let res = await TagService.isTagEditable(this.incoming_Tag.id)
          console.log("Can Edit Tag"+res.data);
          this.can_EditTag = res.data;
        } catch (error) {
          console.log(error);
        }
      }
    },
  };
  </script>
  
  <style></style>