import http from "../http-common";

class TagService {
// Tags
  //Create new tag
  createNewTag = (data) => http.post('/tag', data);

  //update tag
  updateTag = (tag_id,data)=>http.put(`/tag/${tag_id}`,data);

  //delete tag
  deleteTag = (tag_id)=>http.delete(`/tag/${tag_id}`);

  //get tags
  getTags = () => http.get(`/tag`);

  //tags for contact
  //add tag for contact
  addTagforContact = (contact_id,data) => http.post(`/contact_tags/${contact_id}`,data);

  //remove tag for contact
  removeTagforContact = (contact_id,tag_id) => http.delete(`/contact_tags/${contact_id}/tag_id/${tag_id}`);
  
  // get specific tag history for contact
  getContactTagHistory = (contact_id,tag_id) => http.get(`/contact_tag_history/${contact_id}/tag_id/${tag_id}`);

  getAllContactTagHistory = (contact_id) => http.get(`/contact_all_tag_history/${contact_id}`);

  isTagEditable = (tag_id) => http.get(`/tag/edit/${tag_id}`);

  applyMassTagforContact = (data) => http.post(`/masstag`,data);
}
export default new TagService();