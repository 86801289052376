import http from "../http-common";

class AdminService {

    getClients = () => http.get("/clients");

    getClient = (client_id) => http.get(`/client/${client_id}`);
  
    updateClient = (client_id, data) => http.put(`/client/${client_id}`, data);

    updateDesignation = (phones, user_id) => http.put(`/endpointDesignation/${user_id}`, phones);

    updateIVR= (digits, user_id) => http.put(`/ivrDesignation/${user_id}`, digits);

    getPhoneDesignation = (user_id) => http.get(`/endpointDesignation/${user_id}`);

    getIVRDesignation = (user_id) => http.get(`/ivrDesignation/${user_id}`);

    getClientIVR = (client_id) => http.get(`/ivrLength/${client_id}`);
  
    deleteClient = (client_id) => http.delete(`/client/${client_id}`);
  
    getClientPhones = (client_id) => http.get(`/clientphones/${client_id}`);
  
    getClientEndpoints = (client_id) => http.get(`/clientendpoints/${client_id}`);
  
    updatePhone = (data) => http.put(`/clientphone`, data);
  
    deletePhone = (phone_id) => http.delete(`/clientphone/${phone_id}`);

    createNewClient = (data) => http.post("/createClient", data);

    createNewPhone = (data) => http.post("/createPhone", data);
  
    searchNumber = (data) => http.post("/searchNumber", data);

    newEndpoints = () => http.post("newEndpoints");

    updateUserRole = (user_id, role) => http.put(`/users/${user_id}/${role}`);

    subscribe = (priceId) => http.post(`/create-checkout-session/${priceId}/`);

    cancelSubscription = () => http.post(`/cancelsubscription`);

    getSubscriptionDetails = () => http.get(`/subscriptionDetails`);

    switchPlan = (switch_plan) => http.post(`/switchplan/${switch_plan}`);

    planDetails = () => http.get('/planDetails');

    changePlanPrice = (price_id,new_price) => http.post(`/changePlanPrice/${price_id}/${new_price}`);
    
}

export default new AdminService();