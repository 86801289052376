<template>
  <div>
    <v-data-table :headers="headers" :items="client_users" :items-per-page-options="[
      { value: 5, title: '5' },
      { value: 10, title: '10' },
      { value: 15, title: '15' },
      { value: 20, title: '20' },
    ]" item-value="client_id" :sort-by="[{ key: 'client_id', order: 'desc' }]" class="elevation-1">
      <template v-slot:item.is_active="{ item }">
        <v-switch v-model="item.is_active" @click="updateAccountStatus(item.user_id)" color="success" small inset/>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>User EndPoints</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="enable">
            Enable Phone/Text
          </v-btn>
          <v-dialog v-model="phonesDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"> {{ editedItem.username + " Phone List" }} </v-card-title>
              <v-card-text style="margin-top: 10px">
                <v-data-table-virtual v-model="selected" :headers="phone_headers" :items="client_phones"
                  item-value="phone_id" show-select class="elevation-1">
                </v-data-table-virtual>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="closeEdit">Close</v-btn>
                <v-btn color="green" text @click="saveEdit">Save</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="ivrDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"> {{ editedItem.username + " IVR Digits" }} </v-card-title>
              <v-card-text style="margin-top: 10px">
                <v-data-table-virtual v-model="ivr_selected" :headers="[{ title: 'IVR Number', key: 'ivr_des' }]"
                  :items="ivr_list" item-value="ivr_des" show-select class="elevation-1">
                </v-data-table-virtual>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="closeEdit">Close</v-btn>
                <v-btn color="green" text @click="saveIVR">Save</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip location="bottom" text="Edit Phones">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" size="small" class="mr-2" v-if="hasPlivoCreds(item)" @click="editPhones(item)">
              mdi-phone
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="bottom" text="Edit IVR Tree">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" class="mr-2" size="small" v-if="hasPlivoCreds(item) && ivr_list.length > 0"
              @click="editIVR(item)">
              mdi-account
            </v-icon>
          </template>
        </v-tooltip>
      </template>
      <template v-slot:item.role="{ item }">
        <v-select :items="['Admin', 'Member']" v-model="item.role" @update:model-value="() => saveRole(item)"
          variant='underlined' dense hide-details></v-select>
      </template>
      <template v-slot:item.plivo_creds="{ item }">
        {{ plivoCreds(item) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminService from "@/services/adminService";
import router from "@/router";
import MiscService from "@/services/miscService";
export default {
  data: () => ({
    router,
    client_id: 0,
    selected: [],
    ivr_selected: [],
    phone_headers: [
      { title: 'Phone Number', key: 'phone_num' },
    ],
    headers: [
      {
        title: 'User Name',
        align: 'start',
        sortable: true,
        key: 'username',
      },
      { title: 'First Name', key: 'first_name' },
      { title: 'Last Name', key: 'last_name' },
      { title: 'Role', key: 'role' },
      { title: 'Phone/Text Enabled', key: 'plivo_creds', align: "center" },
      { title: 'Active Status', key: 'is_active' },
      { title: 'Edit Phones', key: 'actions', sortable: false },
    ],
    client_users: [],
    client_phones: [],
    ivr_list: [],
    phonesDialog: false,
    ivrDialog: false,
    editedIndex: -1,
    editedItem: {
      user_id: 0,
      username: '',
      role: '',
    },
    defaultItem: {
      user_id: 0,
      username: '',
      role: '',
    }
  }),
  methods: {
    async initialize() {
      this.client_id = this.$route.params.id;
      let client_list = await AdminService.getClientEndpoints(this.client_id);
      this.client_users = client_list.data;

      let phone_list = await AdminService.getClientPhones(this.client_id);
      this.client_phones = phone_list.data;

      let ivr = await AdminService.getClientIVR(this.client_id);
      this.ivr_list = ivr.data;
    },
    hasPlivoCreds(item) {
      // console.log(item);
      return item.plivo_user;
    },
    plivoCreds(item) {
      return (this.hasPlivoCreds(item) ? "Yes" : "No");
    },
    async editPhones(item) {
      this.selected = [];
      let ids = await AdminService.getPhoneDesignation(item.user_id);
      if (!!ids.data && ids.data.length > 0) {
        ids.data.forEach(row => {
          let phone = this.client_phones.find(ph => {
            return ph.phone_id == row.phone_id;
          });
          if (phone)
            this.selected.push(phone.phone_id);
        });
      }
      this.editedIndex = this.client_users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.phonesDialog = true;
    },
    async editIVR(item) {
      let ivr_digits = await AdminService.getIVRDesignation(item.user_id);
      console.log(ivr_digits);
      this.ivr_selected = ivr_digits.data;

      this.editedIndex = this.client_users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.ivrDialog = true;
    },
    closeEdit() {
      this.phonesDialog = false;
      this.ivrDialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      });
    },
    async saveEdit() {
      let selectedObjs = []
      this.selected.forEach((id) => selectedObjs.push(this.client_phones.find((phone) => phone.phone_id === id)));
      await AdminService.updateDesignation(selectedObjs, this.editedItem.user_id);
      this.closeEdit();
    },
    async saveIVR() {
      let selectedObjs = []
      this.selected.forEach((digit) => selectedObjs.push({ ivr_des: digit }));
      await AdminService.updateIVR(selectedObjs, this.editedItem.user_id);
      this.closeEdit();
    },
    async saveRole(item) {
      console.log("saverole", item.user_id, item.role);
      if (item.role === 'Admin') {
        alert('Admin alerted!');
      }
      try {
        await AdminService.updateUserRole(item.user_id, item.role);
      } catch (error) {
        console.log(error);
      }

    },
    async enable() {
      try {
        await AdminService.newEndpoints();
      } catch (error) {
        console.log(error);
      }
    },
    // Update user activation status
    async updateAccountStatus(user_id) {
      try {
        let res = await MiscService.updateActiveStatus(user_id);
        console.log(res);
      } catch (error) {
        console.error(error)
      }
    },
  },

  created() {
    this.initialize();
    console.log(this.client_users)
  },
}
</script>