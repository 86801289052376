import MiscService from "@/services/miscService";
import OdService from "@/services/odService";
// this is the vuex module for the Front Desk Workflow
export const moduleFrontDesk = {
  namespaced: true,
  state: {
    // an array containing all the calls
    calls: [],
    // the current call selected
    call: {},
    
    // true if the calls are loading (they are being received from the server)
    loading: true,
    //all the calls for participant
    allCalls: [],
    //all calls for filtering based on internal convo
    all_calls:[],
    
    allcontact_Typesdata: [],
    alltags_data:[],
    // OD Filter List
    // This Filter's get realtime data from OD
    odFilterList : [
      {id :1,name:'Re-Care',icon:'account-reactivate-outline',color:'#06B994'},
      {id :2,name:'Re-Consultant',icon:'account-tie-voice-outline',color:'#0000AA'},
      {id :3,name:'Un-Confirmed Appointment',icon:'calendar-alert',color:'#FF0000'},
      {id :4,name:'Post-Ops',icon:'badge-account-outline',color:'#A900FF'}
    ],

    odData :[],
    odSelected_value :'',

  },
  getters: {
    all_calls(state){
      return state.all_calls;
    },
    calls(state) {
      return state.calls;
    },
   
    call(state) {
      return state.call;
    },
    get_alltags_data(state){
      return state.alltags_data;
    },
    get_AllContact_Typesdata(state) {
      return state.allcontact_Typesdata;
    },    
    callsByContact(state, getters) {
      return (contact_id) => {
        return state.calls.filter((c) => getters.contactByCall(c) === contact_id);
      };
    },
    voicemails(state, getters) {
      return getters.calls.filter((c) => c.call_missed && c.has_recording);
    },
    unreturnedCalls(state, getters) {
      return getters.calls.filter((c) => c.unreturned);
    },
    // returns the main contact_id associated with the provided call (if any)
    contactByCall() {
      return (call) => {
        // let n = call.comm_participants.find((p) => p.contact_id) || {};
        // return n.contact_id || null;
        let isContact=call.isContact;
        if (isContact){
          let n = call.contact_id || {}
          return parseInt(n) || null;
        }
        return null;
      };
    },
    // returns the main number associated with the provided call (if any)
    numberByCall() {
      return (call) => {
        // let n =
          // call.comm_participants.find((p) => p.contact_id) ||
          // call.comm_participants.find((p) => p.phone_num && !p.user_id) ||
          // {};
        // return n.phone_num || null;
        let n = call.phone_num || call.first_name || {}
        return n || null
      };
    },
    loading(state) {
      return state.loading;
    },
    getAllCalls(state) {
      return state.allCalls
    },
    odFilterList(state){
      return state.odFilterList;
    },
    odData(state){
      return state.odData;
    }
  },
  mutations: {
    all_Calls(state,value){
      state.all_calls = value;
    },
    Calls(state, value) {
      state.calls = value;
    },   
    leftpanel_calls(state, value) {
      state.leftpanel_calls = value;
    },        
    set_alltags_data(state, value) {
      console.log("set_tags_data", value);
      state.alltags_data = value;
    },
    set_AllContact_Typesdata(state, value) {
      console.log("set_data", value);
      state.allcontact_Typesdata = value;
    },
    setcalls(state, value) {
      state.calls = value;
    },
    changeCall(state, value) {
      state.call = value;
    },
    changeLoading(state, value) {
      state.loading = value;
    },
    setAllCalls(state, newState) {
      return state.allCalls = newState;
    },
    updateCallDetails(state,newState) {
      let elementIndex = [];
      let ph1,ph2,ph3;
      ph1=newState[0].ph1;
      if(newState[0].ph1.length>10){
        ph1=newState[0].ph1.substring(newState[0].ph1.length-10);
      }
      ph2=newState[0].ph2;
      if(newState[0].ph2?.length>10){
        ph2=newState[0].ph2.substring(newState[0].ph2.length-10);
      }
      ph3=newState[0].ph3;
      if(newState[0].ph3?.length>10){
        ph3=newState[0].ph3.substring(newState[0].ph3.length-10);
      }
      for (let i = 0; i < state.calls.length ; i++) {
        let callObject = state.calls[i]
        if(callObject.contact_id == newState[0].contact_id){
          callObject.first_name=newState[0].first_name;
          callObject.last_name=newState[0].first_name;
          callObject.ph1=newState[0].ph1;
          callObject.ph2=newState[0].ph2;
          callObject.ph3=newState[0].ph3;
          callObject.textable1=newState[0].textable1;
          callObject.textable2=newState[0].textable2;
          callObject.textable3=newState[0].textable3;
          elementIndex.push(i);
        }else if(callObject.contact_id==newState[0].ph1 ||callObject.contact_id==ph1 ||callObject.contact_id==ph2 ||callObject.contact_id==ph3 ||callObject.contact_id==newState[0].ph2 || callObject.contact_id==newState[0].ph3 ){
          callObject.contact_id=newState[0].contact_id;
          callObject.first_name=newState[0].first_name;
          callObject.last_name=newState[0].first_name;
          callObject.ph1=newState[0].ph1;
          callObject.ph2=newState[0].ph2;
          callObject.ph3=newState[0].ph3;
          callObject.textable1=newState[0].textable1;
          callObject.textable2=newState[0].textable2;
          callObject.textable3=newState[0].textable3;
          callObject.phone_num="";
          elementIndex.push(i);
        }
      }
      console.log(" the elementindex is:"+elementIndex);
      if(elementIndex.length>1){
        console.log("state calls:"+state.calls);
        for(let i=elementIndex.length-1;i!=0;i-- ){
          console.log("removing element at index"+elementIndex[i]+" the location of i"+i);
        state.calls.splice(elementIndex[i], 1);
        console.log("after removing:"+state.calls);
        }
      }
      return state.calls;
    },
    addNewCall(state, newState) {
      let elementIndex = -1;
      for (let i = 0; i < state.calls.length ; i++) {
        let callObject = state.calls[i]
        if(callObject.contact_id == newState[0].contact_id){
          elementIndex = i;
          break;
        }
      }
      
      if(elementIndex != -1)
        state.calls.splice(elementIndex, 1);
      state.calls.unshift(newState[0])
      return state.calls
    },
    callDetails(state, newState) {
      let selectedContactId = state.call.contact_id
      if(selectedContactId == newState[0].contact_id) {
        let newEntry = newState[0]
        state.allCalls.push(newEntry)
	state.allcontact_Typesdata[newEntry.typeName].push(newEntry);
	}
      return state.allCalls
    },
    set_odData(state, newState){
      state.odData = newState;
    },
    append_odData(state, newState){
      state.odData.push(...newState);
    },
    odSelected_value(state, newState){
      state.odSelected_value = newState;
    }
  },
  actions: {

    async retrieveCalls({ commit, getters, rootGetters }) {
      try {
        let res = await MiscService.getCalls();
        
        let calls = res.data.map((c) => {
          if(c == null || c===null){console.log("we have a null");}else{

          const contactsHash = rootGetters["people/contactsHash"];
         if(c.isContact){
            let contact=contactsHash[c.contact_id];
            c["ph1"] = contact?.ph1
            c["ph2"] = contact?.ph2
            c["ph3"] = contact?.ph3
            c["textable1"] = contact?.textable1
            c["textable2"] = contact?.textable2
            c["textable3"] = contact?.textable3
            c["contact_type"] = contact?.contact_type
            c["contact_id"] = contact?.contact_id
            c["tag_id"] = contact?.tags
          } else {
            c["phone_num"] = c?.contact_id
          }
          return c;
        }
      });
      
        const typeHash = rootGetters["people/contactTypes"];
        const tagsHash = rootGetters["tags/tagsHash"];


        let mapper = {};
        let allcontact_Typesdata = {};
        let alltags_data = {};
        console.log("diniwn");

        Object.values(tagsHash).forEach(value => {
          alltags_data[value] = [];
        });        
        console.log("in_alltags_data", alltags_data);
        for (let type of typeHash) {
          mapper[type.type_id] = type.type_name;
          allcontact_Typesdata[type.type_name] = [];
        }

        console.log("mapper", mapper);

        for (let call of calls) {
          let type = call.contact_type;
          let typename = mapper[type];
          if (!allcontact_Typesdata[typename]) {
            allcontact_Typesdata[typename] = [];
          }
          allcontact_Typesdata[typename].push(call);

          if( call.tag_id.length > 0 ){
            for(let t_id of call.tag_id ){
              if(!alltags_data[tagsHash[t_id]]){
                alltags_data[tagsHash[t_id]] = [];
              }
              alltags_data[tagsHash[t_id]].push(call);
            } 
          }
        }

        console.log("allcontact_Typesdata", allcontact_Typesdata);
        commit("set_AllContact_Typesdata", allcontact_Typesdata);      


        commit("Calls", calls);
        commit("all_Calls", calls);
        commit("leftpanel_calls", calls);
        commit("set_alltags_data", alltags_data);

        // now we must update call because the corresponding call in calls may have changed
        if (getters.call.call_id)
          commit("changeCall", getters.calls.find((e) => e.call_id === getters.call.call_id) || {});
        commit("changeLoading", false);
      } catch (err) {
        commit("changeLoading", false);
        throw new Error(err);
      }
    },
    // get contacts from OD based on the type
    async getODdata({ state,commit, rootGetters }, payload){
      let offset = 0;
      let res;
      // if previous value and current selected value  are same update offset
      if(payload===state.odSelected_value){
        if(state.odData.length % 100 === 0){
          offset = state.odData.length;
        }else if (state.odData.length !== 0 ){
          return;
        }
      }
      commit("changeLoading", true);
      
    // Test Data
  //     let res ={data : [ {
  //       call_status: null,
  //       comm_direction: null,
  //       contact_id: 12567,
  //       date: "2024-04-01 00:00:00",
  //       date1: "04/18/2024",
  //       first_name: "Jamesina",
  //       isContact: true,
  //       last_name: "Hanciles",
  //       seqId: "12567_1",
  //       sortField: "2024-04-01T00:00:00.000Z"
  //   },{
  //     call_status: null,
  //     comm_direction: null,
  //     contact_id: 102083,
  //     date: "2024-04-01 00:00:00",
  //     date1: "04/18/2024",
  //     first_name: "Surya",
  //     isContact: true,
  //     last_name: "Dev",
  //     seqId: "102083_2",
  //     sortField: "2024-04-01T00:00:00.000Z"
  // }]};
      try {
        if(payload==='Post-Ops'){
          res = await OdService.getPostOps_List(offset);
          console.log("Post-ops Res:",res);
        }else if(payload==='Re-Consultant'){ 
          res = await OdService.getReConsultant_List(offset);
          console.log("Re-Consultant Res:",res);
        }else if(payload==='Un-Confirmed Appointment'){ 
          res = await OdService.getUnConfirmedAppointment_List(offset);
          console.log("Un-Confirmed Appointment Res:",res);
        }else if(payload=='Re-Care'){ 
          res = await OdService.getRecare_List(offset);
          console.log("Re-Care Res:",res);
        }
      } catch (error) {
        console.log(error);
        commit("set_odData",[]);
        commit("changeLoading", false);
        return;
      }
      if(Array.isArray(res.data) && res.data.length > 0){
        let calls = res.data.map((c) => {
          if(c == null || c===null){console.log("we have a null");}else{

          const contactsHash = rootGetters["people/contactsHash"];
         if(c.isContact){
            let contact=contactsHash[c.contact_id];
            c["ph1"] = contact?.ph1
            c["ph2"] = contact?.ph2
            c["ph3"] = contact?.ph3
            c["textable1"] = contact?.textable1
            c["textable2"] = contact?.textable2
            c["textable3"] = contact.textable3
            c["contact_type"] = contact?.contact_type
            c["contact_id"] = contact?.contact_id
            c["tag_id"] = contact?.tags
            c["call_status"] = -1
            c["comm_direction"] = -1
          } else {
            c["phone_num"] = c.contact_id
            c["call_status"] = -1
            c["comm_direction"] = -1

          }
          return c;
        }
      });
        if(state.odSelected_value === payload){
          // appending next 100 data's
          commit("append_odData",calls);
        }else{
          state.odSelected_value = payload;
          commit("set_odData",calls);  
        }
        commit("changeLoading", false);
    }else{
      commit("set_odData",[]);
      commit("changeLoading", false);
    }
    },
  // Updating allcontact_Typesdata while contact type changing in left panel
  update_contactTypesdata({ state, rootGetters }, payload) {

    let contact = null;

    for (const key of Object.keys(state.allcontact_Typesdata)) {

      const contactsArray = state.allcontact_Typesdata[key];
      const index = contactsArray.findIndex(contact => contact.contact_id === payload.contact_id);
  
      if (index !== -1) {
        [contact] = contactsArray.splice(index, 1);
        break; 
      }
    }
    const typeHash = rootGetters["people/contactTypes"];
    const typeEntry = typeHash.find(type => payload.contact_type === type.type_id);
    const typeName = typeEntry.type_name;
  
    if (!state.allcontact_Typesdata[typeName]) {
      state.allcontact_Typesdata[typeName] = [];
    }
  
    // add the contact to the new type
    state.allcontact_Typesdata[typeName].push(contact);
  },
  add_contactTagsdata({ state,getters, rootGetters }, payload){

    try {
    const tagsHash = rootGetters["tags/tagsHash"];
    const contact = payload.contact_id;
    const tag_id = payload.tag_id;

    let ind = getters.calls.findIndex((e)=> e.contact_id === Number(contact));
    const _call = state.calls[ind];
    
    // for left panel tag ui
    if(state.calls[ind].tag_id){ 
        state.calls[ind].tag_id.push(payload.tag_id);
      }else{
        state.calls[ind].tag_id = [];
        state.calls[ind].tag_id.push(payload.tag_id);
      }

    //for filter
      if(state.alltags_data[tagsHash[tag_id]]){
        state.alltags_data[tagsHash[tag_id]].push(_call);
      }else{
        state.alltags_data[tagsHash[tag_id]] = [];
        state.alltags_data[tagsHash[tag_id]].push(_call);
      }
    } catch (error) {
      console.log(error);
    }
  },
  remove_contactTagsdata({ state,getters, rootGetters }, payload){
    try {
      const tagsHash = rootGetters["tags/tagsHash"];
      const contact = payload.contact_id;
     
      let ind = getters.calls.findIndex((e)=> e.contact_id === Number(contact));
      const _call = getters.calls[ind];
  
      // for left panel tag ui
        state.calls[ind].tag_id = state.calls[ind].tag_id.filter(id => id !== payload.tag_id);
      //for filter
      let index = state.alltags_data[tagsHash[payload.tag_id]].indexOf(_call);
      state.alltags_data[tagsHash[payload.tag_id]].splice(index,1);
      } catch (error) {
        console.log(error);
      }
  }
  },
  
};
