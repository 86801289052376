import TagService from "@/services/tagService";
import EventBus from "@/eventBus";

export const moduleTags = {
  namespaced: true,
  state: {
    tags: [],
    // for v1 we blocked manual tagging
    manual_tagging : false,
  },
  getters: {
    tags(state) {
      return state.tags;
    },
    tagsHash: (state) => {
      return state.tags.reduce((dict, item) => {
        dict[item.tag_id] = item.tag_name;
        return dict;
      }, {});
    },
    tagsHashnew: state => {
      return state.tags.reduce((dict, item) => {
      
        dict[item.tag_id] = item;
        return dict;
      }, {});
      }, 
    can_manual_tagging(state){
      return state.manual_tagging;
    }
  },
  mutations: {
    tags(state, value) {
      state.tags = value;
    },
    // add, update, delete realtime data for tags.
    update_Tag(state, value) {
      const index = state.tags.findIndex((tag) => tag.tag_id === value.tag_id);
      //update
      if (index !== -1) {
        state.tags.splice(index, 1, value);
      }else{//add new tag
        state.tags = [...state.tags, ...[value]];
      }
    },
    delete_Tag(state, value) {
      const index = state.tags.findIndex((tag) => tag.tag_id === value);
      if (index !== -1) {
        state.tags.splice(index, 1);
      }
    },
    manual_tagging(state,value){
      state.manual_tagging = value;
    }
  },
  actions: {
    // get all the tags
    async getTags({ commit }) {
      try {
        let res = await TagService.getTags();
        console.log("tags,", res.data);
        commit("tags", res.data);
      } catch (error) {
        console.error(error)
      }
      
    },
    async createTag({ commit }, payload) {
      try {
        const res = await TagService.createNewTag(payload);
        console.log("createTag res", res);
        EventBus.$emit("alert", {
          message: "Tag Created Successfully",
          type: "success-snack",
        });
      } catch (error) {
        console.error(error);
        EventBus.$emit("alert", {
          message: "Failed To Create New Tag",
          type: "error-snack",
        });
      }
    },
    async updateTag({ commit }, payload) {
      try {
        const response = await TagService.updateTag(payload.tag_id,payload.tag_data);
        console.log("updatetag res", response);
        EventBus.$emit("alert", {
          message: "Tag Updated Successfully",
          type: "success-snack",
        });
      } catch (error) {
        console.error(error);
        EventBus.$emit("alert", {
          message: "Failed To Update Tag",
          type: "error-snack",
        });
      }
    },
    async deleteTag({ commit }, payload) {
      try {
       let  response = await TagService.deleteTag(payload);
        console.log("deletetag res", response);
        EventBus.$emit("alert", {
          message: "Tag Deleted Successfully",
          type: "success-snack",
        });
        
      } catch (error) {
        console.error(error);
        EventBus.$emit("alert", {
          message: "Failed To Delete Tag",
          type: "error-snack",
        });
      }
    },
    //add tag to particular contact
    async addTagforContact({ commit }, payload) {
      try {
        let  response = await TagService.addTagforContact(payload.contact_id,payload);
         console.log("Tag added res", response.data.data);
         if(response.status === 201)
         EventBus.$emit("alert", {
           message: "Tag Added Successfully",
           type: "success-snack",
         });
       } catch (error) {
         console.error(error);
         EventBus.$emit("alert", {
           message: "Failed To Add Tag",
           type: "error-snack",
         });
       }
    },
    async removeTagForContact({ commit }, payload) {
      try {
        let  response = await TagService.removeTagforContact(payload.contact_id,payload.tag_id);
         console.log("Tag removed res", response);
         if(response.status === 200){
            EventBus.$emit("alert", {
              message: "Tag Removed Successfully",
              type: "success-snack",
          });
         }
        
       } catch (error) {
         console.error(error);
         EventBus.$emit("alert", {
           message: "Failed To Remove Tag",
           type: "error-snack",
         });
       }
    },
    async applyMassTagforContact({ commit },payload) {
      try {
        console.log(" Mass Tag added Payload", payload);
        if(payload.contact_ids.length === 0 || payload.tag_ids.length===0){
          EventBus.$emit("alert", {
            message: (payload.contact_ids.length === 0) ?"No Contacts Selected" : "No Tags Selected",
            type: "warning-snack",
          });
          return;
        }
        let  response = await TagService.applyMassTagforContact(payload);
         console.log(" Mass Tag added res", response);
         EventBus.$emit("alert", {
           message: "Tag Added Successfully",
           type: "success-snack",
         });
       } catch (error) {
         console.error(error);
         EventBus.$emit("alert", {
           message: "Failed To Add Tag",
           type: "error-snack",
         });
       }
    }
  },
};