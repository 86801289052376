<template>
<div>
  <v-data-table
    :headers="headers"
    :items="client_phones"
    :items-per-page-options="[
      { value: 5, title: '5' },
      { value: 10, title: '10' },
      { value: 15, title: '15' },
      { value: 20, title: '20' },
    ]"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Phones</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="addNew"
        >
          Add New Phone
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-banner text="Are you sure you want to delete this item ?" 
            color="warning"
            icon="mdi-alert-outline"
            lines="one"
          >
            <template v-slot:actions >
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="green" text @click="deleteItemConfirm">OK</v-btn>
            </template>
          </v-banner>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
    <div v-if="item.phone_id === editedItem.phone_id">
        <v-tooltip location="bottom" text="Cancel">
          <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            class="mr-2"
            size="small"
            color="red"
            @click="cancelEdit"
          >
            mdi-cancel
          </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="bottom" text="Save">
          <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            class="mr-2"
            size="small"
            color="green"
            @click="createPhoneClick"
          >
            mdi-content-save
          </v-icon>
          </template>
        </v-tooltip>
      </div>
      <div v-else>
        <v-tooltip location="bottom" text="Edit">
        <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            class="mr-2"
            size="small"
            color="primary"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
        </v-tooltip>
        <v-tooltip location="bottom" text="Delete">
        <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            size="small"
            color="red"
            v-if="item.ported == false"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:item.phone_num="{ item }" >
      <v-text-field v-model="editedItem.phone_num" disabled variant="underlined" ref="phone_field" :hide-details="true" density="compact" single-line style="width: 100px" v-if="item.phone_id === editedItem.phone_id"></v-text-field>
      <span v-else>{{ phoneFormat(item.phone_num) }}</span>   
    </template>
    <template v-slot:item.description="{ item }" >
      <TableEditField
        v-if="item.phone_id === editedItem.phone_id"
        :value="editedItem.description"
        ref="desc_field"
        :rules="[]"
        width="150"
        @updated="(val) => (editedItem.description = val)"
      />
      <span v-else>{{ item.description }}</span>   
    </template>
    <template v-slot:item.ported="{ item }" >
        {{ (item.ported ? "Yes" : "No") }}
    </template>
  </v-data-table>
  <div style="display: flex; align-items: center; align-items: center" v-if="createdObj">
      <v-form ref="entryForm" @submit.prevent="searchNumber()" style="margin-left: 40px; margin-top: 75px;">
        <v-radio-group mandatory v-model="lookupType">
            <v-radio label="City, State" value="1" selected />
            <v-radio label="Area Code" value="2" />
        </v-radio-group>
        <div v-if="lookupType == 1">
            <v-text-field
                style="width: 200px"
                label="City"
                v-model="city"
                outlined
                dense
            ></v-text-field>
            <v-select
                style="width: 200px"
                label="State"
                :items="states"
                v-model="state"
                outlined
                dense
            ></v-select>
        </div>
        <v-text-field v-else
            style="width: 200px"
            label="Preferred Area Code"
            v-model="areaCode"
            outlined
            dense
        ></v-text-field>
        <v-btn style="width: 200px" color="primary" type="submit">Search Number</v-btn>
      </v-form>
      <v-container v-if="!!resultNumber" style="margin-left: 80px; margin-top: 100px; width: 350px">
          <v-row >
              <v-col>Number:</v-col>
              <v-col>{{ phoneFormatTitle(resultNumber.substring(1)) }}</v-col>
          </v-row>
          <v-row>
              <v-col >City:</v-col>
              <v-col>{{ resultCity }}</v-col>
          </v-row>
          <v-row v-if="!!resultNumber && !!resultCity">
              <v-col>
                  <v-btn style="width: 200px" color="primary" @click="addNumber()">
                      Use This Number
                  </v-btn>
              </v-col>
          </v-row>
      </v-container>
    </div>
  </div>
</template>


<script>
import AdminService from "@/services/adminService";
import router from "@/router";
import { states, phoneFormat, phoneFormatTitle } from "@/util/util"
import TableEditField from "../CustomComponents/TableEditField.vue";

export default {
    components: { TableEditField },
    data: () => ({
      router,
      states,
      dialogDelete: false,
      client_id: 0,
      headers: [
        {
          title: 'Phone Number',
          align: 'start',
          sortable: true,
          key: 'phone_num',
        },
        { title: 'Ported', key: 'ported', align: 'center', sortable: false },
        { title: 'Description', key: 'description', sortable: false },
        { title: 'Actions', key: 'actions', sortable: false },
      ],
      client_phones: [],
      editedIndex: -1,
      editedItem: {
        phone_id: 0,
        client_id: 0,
        phone_num: '',
        description: '',
        ported: false,
      },
      defaultItem: {
        phone_id: 0,
        client_id: 0,
        phone_num: '',
        description: '',
        ported: false,
      },
      city: null,
      state: null,
      areaCode: null,
      newNumber: null,
      lookupType: 1,
      resultNumber: null,
      resultCity: null,
      createdObj: false,
      rules: {
        required: (value) => !!value || "Required.",
        areaCode: (value) => {
            const pattern = /^\d{3}$/;
            return pattern.test(value) || "Invalid area code."
        },
      },
      phoneNumberRules: [],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize();
    },

    methods: {
      phoneFormat,
      phoneFormatTitle,
      async initialize () {
        let client_list = await AdminService.getClientPhones(this.$route.params.id);
        this.client_phones = client_list.data;
        this.client_id = this.$route.params.id;
      },

      editItem (item) {
        this.editedIndex = this.client_phones.indexOf(item)
        this.editedItem = Object.assign({}, item)
      },

      cancelEdit() {
        if(this.editedItem.phone_id == 0) {
          this.client_phones.splice(this.editedIndex, 1);
          this.createdObj = false;
        }
        this.close();
      },

      deleteItem (item) {
        this.editedIndex = this.client_phones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        await AdminService.deletePhone(this.client_phones[this.editedIndex].phone_id)
        this.client_phones.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {

      },
      async createPhoneClick() {
          let exists = !!this.editedItem.phone_num;
          if(exists) {
            if(this.editedItem.phone_id == 0) {
              const data = {
                  phoneNumber: this.editedItem.phone_num,
                  description: this.editedItem.description,
                  client_id: this.client_id,
              };
              let response = await AdminService.createNewPhone(data);
              Object.assign(this.client_phones[this.editedIndex], response.data.phone);
              this.createdObj = false;
            } else {
              await AdminService.updatePhone(this.editedItem)
              Object.assign(this.client_phones[this.editedIndex], this.editedItem);
            }
            this.close();
          } else {
              this.phoneNumberRules = [this.rules.required];

              this.$refs.phone_field.validate();
          }
      },
      searchNumber() {
          var data;
          
          this.resultCity = null;
          this.resultNumber = null;

          if(this.lookupType == 1) {
            if(!!this.city && !!this.state)
                data = {searchType: "city", areaCode: null, officeCity: this.city, officeState: this.state};
          } else {
            let exists = !!this.areaCode;
            if(exists)
                data = {searchType: "area", areaCode: this.areaCode, officeCity: null, officeState: null};
          }
          
          AdminService.searchNumber(data)
          .then((response) => {
              if(response.data.totalCount == "1") {
                this.resultNumber = response.data.resultNumber;
                this.resultCity = response.data.resultCity;
                this.phoneReturned = true;
              } else {
                this.resultNumber = " No phone numbers found!";
                this.phoneReturned = false;
              }
          }).catch((e) => {
              this.resultNumber = " Error Finding Phone! Double Check Your Inputs!";
              this.phoneReturned = false;
              console.log(e);
          });   
      },
      addNumber() {
          this.editedItem.phone_num = this.resultNumber.slice(1);
      },
      addNew() {
        if(!this.createdObj && this.client_phones.length < 10) {
          this.createdObj = true;
          const addObj = Object.assign({}, this.defaultItem);
          this.client_phones.unshift(addObj);
          this.editItem(addObj);
        }
      },
    },
  }
</script>